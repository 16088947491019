/* eslint-disable import/prefer-default-export */
export const getUrlParameterByName = (name, url) => {
    const location = url || window.location.href
    const replacedName = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp(`[?&]${replacedName}(=([^&#]*)|&|#|$)`)
    const results = regex.exec(location)
    if (!results) return null
    if (!results[2]) return ''
    return window.decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const isValidUrl = (url) => {
    const pattern = new RegExp(
        '^(https?:\\/\\/)' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i',
    ) // fragment locator
    return !!pattern.test(url)
}

export const isNewTabPage = () => {
    return window.location.pathname === '/newtab/'
}
