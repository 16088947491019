import axios from 'axios'
import {IS_PROD} from '../env'

const ORIGIN = IS_PROD ? 'https://www.startpage.com' : 'http://localhost:8080'

const SEND_ONCE = {}

export class Alert {
    constructor(url) {
        this.url = url || `${ORIGIN}/sp/invalid-marketing-param`
    }

    send(params) {
        return axios.get(this.url, {params})
    }

    sendOnce(params) {
        const strParams = JSON.stringify(params)
        if (!SEND_ONCE[strParams]) {
            SEND_ONCE[strParams] = true
            return this.send(params)
        }
        return Promise.resolve()
    }
}
